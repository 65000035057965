import React from "react";

import { graphql } from "gatsby";

import { useTranslation } from "gatsby-plugin-react-i18next";

import HalfHero from "components/HalfHero";
import Next from "components/Next";
import PageContainer from "components/PageContainer";
import SEO from "components/SEO";

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <HalfHero
        title={
          <>
            {t("404 Error:")}
            <br />
            {t("Page Not Found")}
          </>
        }
        description={t("We couldn't find the page you're looking for.")}
      />
      <Next text={t("Return Home")} to="/" />
    </PageContainer>
  );
};

export default NotFoundPage;

// eslint-disable-next-line react/prop-types
export const Head = ({ location: { pathname } }) => (
  <SEO title="404 Error" pathname={pathname} />
);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["error", "common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
